<template>
<!-- controlsList="nodownload" 隐藏下载按钮 -->
    <div id="video">
         <video id="playVideos" controls loop="loop" autoplay="autoplay" width="100%" height="100%" controlsList="nodownload">
             <!-- video/mp4 -->
                <source id="playVideosss" :src="playVideoUrl" type="video/mp4">
        </video>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                playVideoUrl:'',
            }
        },
        created () {
           
        },
        mounted () {
             this.playVideo();
        },
        methods: {
            playVideo(){
                // console.log('id',this.$route.query.videoId)
                // var url = require("../assets/video/"+ this.$route.query.videoId)
                var url = this.$route.query.videoId;
                // console.log('url', "../assets/video/"+ this.$route.query.videoId);
                this.playVideoUrl = url;
                let vdo = document.getElementById("playVideos")
                vdo.src = url;
                vdo.play();
                    
                // this.$axios.get(``).then(res => {
                //     console.log('地址', res);
                    
                    
                //     let vdo = document.getElementById("playVideos")
                //     vdo.src = url;
                //     vdo.play();

                //     }).catch(err=>{
                // })

            },
        },
    }
</script>

<style lang="scss" scoped>
    #video{
        width: 100%;
        height: 100%;
        background-color: black;
    }
    .video {
            width: 100%;
            height: 100%;
            background-color: #000;
            // margin: 50px auto;
        }

    .video video {
            width: 100%;
            height: 100%;
            display: block;
        }

    //整个控制
    video::-webkit-media-controls-enclosure{
        width: 70%;
    }
    video::-webkit-media-controls-enclosure {
         overflow: hidden;
    }
    video::-webkit-media-controls-panel {
        // width: calc(100% + 30px);
        width: 50%;
    }

    //全屏按钮
     video::-webkit-media-controls-fullscreen-button {
        display: none;
    }      

    // // 进度条
    //    video::-webkit-media-controls-timeline{
    //       width: 50%;
    //       position: absolute;
    //       right: 0;
    //       margin-top: 100px;
    //    }




</style>